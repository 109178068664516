import styled from "styled-components";

const Root = styled.div`
    border-radius: 50px 50px 0 0;
    background: var(--Gray-50, #F9FAFB);
    box-shadow: 0 -5px 8px 0 rgba(0, 0, 0, 0.10);
`

export default function MainCard({children}: { children: React.ReactNode }) {
    return (//todo: mudar height da div pai depois
        <Root className="main_card bg-white min-h-[100vh]">
            <div style={{padding: '0 16px', minHeight: '100%'}} className='mt-[50px]'>
                {children}
            </div>
        </Root>
    )
}