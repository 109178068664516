"use client"

import './mobile-search.css';
import React, {useRef, useState} from 'react';
import MobileInputAutocomplete from "@/components/molecules/MobileInputAutocomplete/mobile-input-autocomplete";
import AutoCompleteStore from "@/components/molecules/MobileInputAutocomplete/AutoCompleteStore";
import InputWithLabel from "@/components/atoms/inputs/input-with-label";
import buildSearchQueryParam from "@/services/search/build-search-query-param";
import {strlen} from "stylis";
import { useNavigate } from "react-router-dom";

const MobileSearch = () => {
    const [search, setSearch] = useState('')
    const storeLocation = useRef(new AutoCompleteStore()).current;
    const navigate = useNavigate();

    function handleSearch() {
        let query = {}
        if(storeLocation.selectedItem && storeLocation.selectedOption) {
            query = buildSearchQueryParam(storeLocation.selectedItem, storeLocation.selectedOption);
        }
        if(search) {
            query = {...query, search}
        }
        let queryString = new URLSearchParams(query).toString();
        queryString = strlen(queryString) > 0 ? '?' + queryString : '';
        navigate(`/vagas${queryString}`)
    }

    return (
        <div className="mobile_search">
            <div className="mobile_search-internal_div">
                <div className="w-full mobile_search-main-text_div">
                    <h2 className="mobile_search-main_text">Todas as suas vagas em um unico lugar.</h2>
                </div>
                <InputWithLabel
                    label='O que você procura?'
                    placeholder='Cargo ou Requisito'
                    value={search}
                    id="input-title"
                    setValue={(value: string) => setSearch(value)}
                />

                <MobileInputAutocomplete
                    label='Onde?'
                    placeholder='Estado ou Cidade'
                    store={storeLocation}
                />
                <button className='mobile_search-search_button' onClick={handleSearch}>
                    <img src="/images/icons/search.svg" alt="Lupa de pesquisa" width={20} height={20}/>
                    <span>Buscar vagas</span>
                </button>
            </div>
        </div>
    );
};

export default MobileSearch;
