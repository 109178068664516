import httpClient from "@/utils/http-client";
import {strlen} from "stylis";
import {SearchVacancyResume} from "@/services/search-vacancies";

export type PaginateVacanciesProps = { search?: string, location?: string, page: string };

export default async function paginateVacancies(query: PaginateVacanciesProps): Promise<SearchVacancyResume[]> {
    let queryString = new URLSearchParams(query).toString();
    queryString = strlen(queryString) > 0 ? '?' + queryString : '';
    const output = await httpClient.get<SearchVacancyResume[]>('/api/vacancies/paginate' + queryString);
    return output.data;
}