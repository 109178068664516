import React, {CSSProperties} from 'react';
const mainDiv: CSSProperties = {
    borderRadius: '14px',
    border: '1px solid #EAECF0',
    background: '#FFF',
    minWidth: '150px',
};

const internalDiv: CSSProperties = {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    flex: '1 0 0',
}
const titleStyle: CSSProperties = {
    color: '#101828',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
};
const vacancyCountSpan: CSSProperties = {
    color: 'var(--Brand-900, #42307D)',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '28px',
};

const locationSpan: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: '#344054',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
};

const seeMoreSpan: CSSProperties = {
    color: '#6941C6',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    cursor: 'pointer',
};

export type VacancyCountCardProps = {
    title: string;
    vacancyQty: string;
    location: string;
    url: string;
}

const VacancyCountCard = ({title, vacancyQty, location, url}: VacancyCountCardProps) => {

    return (
        <div style={mainDiv}>
            <div style={internalDiv}>
                <span style={titleStyle}>{title}</span>
                <span style={vacancyCountSpan}>{vacancyQty} Vagas</span>
                <span style={locationSpan}>
                    <img src='/images/icons/map-pin.svg' alt="Icone de Mapa" width={10} height={10}/> {location}
                </span>
                <span style={seeMoreSpan} className='flex gap-1'>Ver mais <img
                    src='/images/icons/arrow-right.svg'
                    alt="Seta para direita" width={10}
                    height={10}/>
                </span>
            </div>
        </div>
    );
};

export default VacancyCountCard;