import './recent-search-tag.css'
import React from "react";

export default function RecentSearchTag({children}: { children: React.ReactNode }) {
    return (
        <div className="recent_search_tag-main_div">
            <span className="recent_search_tag-label">
                <img src='/images/icons/search/history.svg' alt="Relogio" width={20} height={20}/>
                {children}
            </span>
            <img src="/images/icons/search/chevron-right.svg" alt="Seta para direita" width={20} height={20}/>
        </div>
    )
}