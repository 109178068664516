import React from 'react';
import { LuHeart } from "react-icons/lu";
import LoadingContent from "@/components/atoms/loading/loading-content";

const styles = {
    mainDiv: {
        display: 'flex',
        padding: '20px',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        gap: '20px',
        borderRadius: '25px',
        background: '#FFF',
        border: '2px solid #EAECF0',
        backgroundColor: '#FFF',
    },
    companyLogo: {
        width: '80px',
        color: '#98A2B3',
        fontSize: '14px',
    },
    vacancyInfo: {
        width: '100%',
        display: 'grid',
        gap: '10px',
    },
    content: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        gap: '10px',
    },
    favoriteIcon: {
        width: '20px',
        cursor: 'pointer',
        color: '#98A2B3',
    },
};


const VacancyResumeCardLoading = () => {
    return (
        <div style={styles.mainDiv} className='hover:shadow-xl'>
            <LoadingContent width='80px' height='60px'/>
            <div style={styles.content}>
                <div style={styles.vacancyInfo}>
                    <LoadingContent />
                    <LoadingContent />
                    <LoadingContent />
                </div>
                <LuHeart size={20} style={styles.favoriteIcon}/>
            </div>
        </div>
    );
};

export default VacancyResumeCardLoading;