import React from 'react';
import './navbar-mobile.css';

const NavbarMobile = () => {
    return (
        <nav className='navbar-mobile'>
            <img
                src="/images/icons/menu/menu-icon.svg"
                alt="Icone menu"
                className="navbar-mobile_menu-hamburger"
                width={20}
                height={20}
            />
            <div className="navbar-mobile_nav-items">
                <img
                    src="/images/logo/workfly_full_logo.svg"
                    className="navbar-mobile_company-logo"
                    alt="Logo Workfly"
                    width={100}
                    height={20}
                />
                <button className="text-purple-700 navbar-mobile_login-button hover:bg-[#b99dfa]">
                    <img
                        src="/images/icons/log-in.svg"
                        alt="Icone de entrar"
                        width={20}
                        height={20}
                    />
                    <span>Entrar</span>
                </button>
            </div>
        </nav>
    );
};

export default NavbarMobile;
