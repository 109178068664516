import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/globals.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "@/pages/home";
import Vagas from "@/pages/vagas";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>
    },
    {
        path: "/vagas",
        element: <Vagas />
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
