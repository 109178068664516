import './mobile-input-autocomplete.css';

import InputWithLabel from "@/components/atoms/inputs/input-with-label";
import AutoCompleteStore from "@/components/molecules/MobileInputAutocomplete/AutoCompleteStore";
import {observer} from "mobx-react-lite";
import React, {useState} from 'react';
import AutocompleteSelectDropdown, {
    AutocompleteItem,
    Option
} from "@/components/molecules/DropdownItemsSelect/autocomplete-select-dropdown";

export type MobileInputDropdownProps = {
    label: string;
    placeholder: string;
    store: AutoCompleteStore;
};


const MobileInputAutocomplete: React.FC<MobileInputDropdownProps> = observer(({store, label, placeholder}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const handleChange = (value: string) => {
        store.setQuery(value);
    };

    const handleSelectItem = (item: AutocompleteItem, option: Option) => {
        store.setQuery(option.name);
        store.setSelectedItem(item);
        store.setSelectedOption(option);
        setShowDropdown(false);
    }

    return (
        <div className="mobile_search-input-with-autocomplete">
            <InputWithLabel
                label={label}
                placeholder={placeholder}
                value={store.query}
                id="input-title"
                setValue={handleChange}
                onFocus={() => setShowDropdown(true)}
            />
            {
                showDropdown && store.suggestions.length > 0 && (
                    <AutocompleteSelectDropdown
                        onSelectItem={(item: AutocompleteItem, option: Option) => handleSelectItem(item, option)}
                        autocomplete={store.suggestions}
                        onClickOutside={() => setShowDropdown(false)}
                    />
                )
            }

        </div>
    )
});

export default MobileInputAutocomplete;