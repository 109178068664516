"use client";

import './input-with-label.css';
import React from 'react';

export type InputWithLabelProps = {
    id: string;
    label: string;
    placeholder: string;
    value: string;
    setValue: (value: string) => void;
    onFocus?: () => void;
};

const InputWithLabel = ({id, label, placeholder, value, setValue, onFocus}: InputWithLabelProps) => {
    const handleFocus = () => {
        if(!onFocus) return;

        setTimeout(() => onFocus(), 200);
    };

    const handleChange = (e: any) => {
        setValue(e.target.value);
    };

    return (
        <div className="mobile_search-input-section">
            <label className={`mobile_search-label`} htmlFor={id}>{label}</label>
            <input
                type="text"
                id={id}
                placeholder={placeholder}
                className={`mobile_search-input`}
                value={value}
                onChange={handleChange}
                onFocus={handleFocus}
            />
        </div>
    );
};

export default InputWithLabel;
