import { makeObservable, observable, action, runInAction } from 'mobx';
import debounce from 'lodash.debounce';
import type {AutocompleteItem, Option} from "@/components/molecules/DropdownItemsSelect/autocomplete-select-dropdown";
import httpClient from "@/utils/http-client";

class AutoCompleteStore {
    @observable query: string = '';
    @observable suggestions: AutocompleteItem[] = [];
    @observable isLoading: boolean = false;
    @observable selectedItem: AutocompleteItem|null = null;
    @observable selectedOption: Option|null = null;

    constructor() {
        makeObservable(this);
        this.fetchSuggestions = debounce(this.fetchSuggestions, 500) as (query: string) => Promise<void>;
    }

    @action
    setQuery(query: string) {
        this.query = query;
        this.fetchSuggestions(query);
    }

    @action
    setSelectedItem(item: AutocompleteItem) {
        this.selectedItem = item;
    }

    @action
    setSelectedOption(option: Option) {
        this.selectedOption = option;
    }

    @action
    async fetchSuggestions(query: string): Promise<void> {
        if (query.trim() === '') {
            runInAction(() => {
                this.suggestions = [];
            });
            return;
        }

        this.isLoading = true;
        try {
            const response = await httpClient.get<AutocompleteItem[]>(`/api/search/autocomplete/location/${query}`);
            runInAction(() => {
                this.suggestions = response.data;
                this.isLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
                console.error('Failed to fetch suggestions', error); //todo: criar tratativa de erros
            });
        }
    }
}

export default AutoCompleteStore;
