import React from 'react';
import {VacancyWorkType, WorkTypeName} from "@/services/search-vacancies";
import {LuBuilding, LuHeart, LuLaptop, LuRadar, LuTrain} from "react-icons/lu";
const styles = {
    mainDiv: {
        display: 'flex',
        padding: '20px',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
        gap: '20px',
        borderRadius: '25px',
        background: '#FFF',
        border: '2px solid #EAECF0',
        backgroundColor: '#FFF',
    },
    companyLogo: {
        width: '80px',
        color: '#98A2B3',
        fontSize: '14px',
    },
    vacancyInfo: {
        width: '100%',
        display: 'grid',
        gap: '4px',
    },
    vacancyTitle: {
        alignSelf: 'stretch',
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '20px',
    },
    companyName: {
        color: '#98A2B3',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '18px',
    },
    location: {
        display: 'flex',
        alignItems: 'flex-end',
        gap: '2px',
        color: '#98A2B3',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '18px',
    },
    workType: {
        color: '#039855',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '18px',
        display: 'flex',
        gap: '2px',
    },
    content: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
    },
    favoriteIcon: {
        width: '20px',
        cursor: 'pointer',
    },
};

export type VacancyResumeCardProps = {
    companyLogo: string|null;
    companyName: string;
    vacancyTitle: string;
    location: string;
    workType: VacancyWorkType;
    postedAt: string;
}
const IconByWorkType = ({workType}: {workType: WorkTypeName}) => {
    if(workType === 'PRESENCIAL') return <LuBuilding size={15} />
    if(workType === 'HIBRIDO') return <LuTrain size={15} />
    if(workType === 'HOME_OFFICE') return <LuLaptop size={15} />
    if(workType === 'NAO_INFORMADO') return <LuRadar size={15} />
}

const VacancyResumeCard = ({ companyLogo, companyName, vacancyTitle, location, workType, postedAt }: VacancyResumeCardProps) => {

    return (
        <div style={styles.mainDiv} className='hover:shadow-xl'>
            <img
                src={companyLogo ?? 'images/icons/building-line.svg'}
                alt={`Logo da empresa ${companyName || 'Não informada'}`}
                style={styles.companyLogo}
            />
            <div style={styles.content}>
                <div style={styles.vacancyInfo}>
                    <span style={styles.vacancyTitle}>{vacancyTitle}</span>
                    {companyName && <span style={styles.companyName}>{companyName}</span>}
                    <span style={styles.location}>
                        <img
                            src='/images/icons/map-pin.svg'
                            alt="Icone de localização"
                            width={15}
                            height={15}
                        /> {location}
                    </span>
                    <span style={styles.workType}>
                        <IconByWorkType workType={workType.name}/> {workType.value}
                    </span>
                    <span style={styles.location}>{postedAt}</span>
                </div>
                <LuHeart size={20} style={styles.favoriteIcon}/>
            </div>
        </div>
    );
};

export default VacancyResumeCard;