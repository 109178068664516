import MobileLayout from "@/components/templates/MobileLayout/mobile-layout";
import VacancyResumeCard from "@/components/molecules/VacancyResumeCard/vacancy-resume-card";
import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {SearchVacanciesStore, useSearchVacanciesStore} from "@/hooks/stores/search-vacancies-store";
import {observer} from "mobx-react";
import VacancyResumeCardLoading from "@/components/molecules/VacancyResumeCard/vacancy-resume-card-loading";
import {SearchVacanciesProps, SearchVacancyResume} from "@/services/search-vacancies";
import LoadingContent from "@/components/atoms/loading/loading-content";
import {PaginateVacanciesStore, usePaginateVacanciesStore} from "@/hooks/stores/paginate-vacancies-store";

const fakeLoadings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]; //todo: mudar isso para algo mais "elegante" no futuro
const vacancyQtyResumeStyle = {
    color: 'var(--Gray-400, #98A2B3)',
    fontFamily: 'var(--font-familly-inter)',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '18px',
}

const Vagas = observer(() => {
    const [params] = useSearchParams();
    const vacancyStore: SearchVacanciesStore = useSearchVacanciesStore();
    const paginateStore: PaginateVacanciesStore = usePaginateVacanciesStore();

    useEffect(() => {
        vacancyStore.setFilters(params as SearchVacanciesProps);
    }, [params, vacancyStore]);

    function handleScrollFinish() {
        if (paginateStore.isLoading) return;
        paginateStore.nextPage();
    }

    return (
        <MobileLayout onScrollFinish={handleScrollFinish}>
            <div className='w-full'>
                {
                    vacancyStore.isLoading
                        ? <LoadingContent/>
                        : (
                            <h2 style={vacancyQtyResumeStyle}>
                            <span
                                className='text-brand-600 font-bold'>{vacancyStore.output?.total ?? 0}
                            </span> Vagas de
                                Emprego encontradas
                            </h2>
                        )
                }
                <div className='grid gap-2 mt-2'>
                    {
                        vacancyStore.isLoading && <RenderFakeLoadings/>
                    }
                    {
                        !vacancyStore.isLoading && vacancyStore.output?.results.map((vacancy) => (
                            <VacancyResumeCard
                                key={vacancy.token}
                                vacancyTitle={vacancy.title}
                                companyName={vacancy.company_name}
                                companyLogo={vacancy.company_logo}
                                location={vacancy.location}
                                workType={vacancy.work_type}
                                postedAt={vacancy.posted_at}
                            />
                        ))
                    }
                    {
                        paginateStore.vacancies.map((vacancy: SearchVacancyResume) => (
                                <VacancyResumeCard
                                    key={vacancy.token}
                                    vacancyTitle={vacancy.title}
                                    companyName={vacancy.company_name}
                                    companyLogo={vacancy.company_logo}
                                    location={vacancy.location}
                                    workType={vacancy.work_type}
                                    postedAt={vacancy.posted_at}
                                />
                            )
                        )
                    }
                    {
                        paginateStore.isLoading && <RenderFakeLoadings/>
                    }
                </div>
            </div>
        </MobileLayout>
    )
});

function RenderFakeLoadings() {
    return fakeLoadings.map((_, index) => (
        <VacancyResumeCardLoading key={index}/>
    ))
}

export default Vagas;