import { makeObservable, observable, action, runInAction } from 'mobx';
import { SearchVacancyResume } from "@/services/search-vacancies";
import type { SearchVacanciesProps } from "@/services/search-vacancies";
import useActivateStore from "@/hooks/stores/use-activate-store";
import paginateVacancies from "@/services/paginate-vacancies";


export class PaginateVacanciesStore {
    @observable vacancies: SearchVacancyResume[] = [];
    @observable filters: SearchVacanciesProps = {};
    @observable isLoading: boolean = false;
    @observable hasError: boolean = false;
    @observable page: number = 1;

    constructor() {
        makeObservable(this);
    }

    @action
    setVacancies(vacancies: SearchVacancyResume[]) {
        this.vacancies = vacancies;
    }

    @action
    nextPage() {
        this.page = this.page + 1;
        this.getVacanciesForPage();
    }

    @action
    setLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @action
    async getVacanciesForPage(): Promise<void> {
        this.isLoading = true;
        try {
            const response: SearchVacancyResume[] = await paginateVacancies({...this.filters, page: `${this.page}`});
            console.log({response})
            runInAction(() => {
                this.vacancies = this.vacancies.concat(response);
                this.isLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
                console.error('Failed to fetch suggestions', error); //todo: criar tratativa de erros
            });
        }
    }
}

export const usePaginateVacanciesStore = () => useActivateStore<PaginateVacanciesStore>(new PaginateVacanciesStore());