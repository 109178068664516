import MobileLayout from "@/components/templates/MobileLayout/mobile-layout";
import PurpleTag from "@/components/atoms/tags/PurpleTag/purple-tag";
import RecentSearchTag from "@/components/atoms/tags/RecentSearchTag/recent-search-tag";
import {CSSProperties} from "react";
import VacancyCountCard from "@/components/molecules/VacancyCountCard/vacancy-count-card";

const spanStyle: CSSProperties = {
    color: 'var(--Gray-700, #344054)',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px' /* 150% */
}

const spanDivStyle: CSSProperties = {
    display: 'flex',
    padding: '0px 16px',
    alignItems: 'flex-start',
    gap: '10px',
    alignSelf: 'stretch',
}

const vacancyCountContentDiv: CSSProperties = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    gap: '16px',
    padding: '16px 0',
}

export default function Home() {
    return (
        <MobileLayout>
            <div className='w-full h-full'>
                <PurpleTag><span>Buscas Recentes</span></PurpleTag>
                <div className='w-full mt-[26px] grid gap-[10px]'>
                    <RecentSearchTag>Desenvolvedor Frontend</RecentSearchTag>
                    <RecentSearchTag>Desenvolvedor Fullstack Pleno</RecentSearchTag>
                    <RecentSearchTag>Desenvolvedor PHP</RecentSearchTag>
                </div>
                <div className='mt-[26px]'>
                    <div style={spanDivStyle}>
                        <span style={spanStyle}>Saiba mais sobre oportunidades profissionais ao redor do mundo!</span>
                    </div>
                    <div style={vacancyCountContentDiv}>
                        <VacancyCountCard title='Page em Destaque' vacancyQty={'12,334'} location='São Paulo' url={'#'} />
                        <VacancyCountCard title='Page em Destaque' vacancyQty={'12,334'} location='São Paulo' url={'#'} />
                        <VacancyCountCard title='Page em Destaque' vacancyQty={'12,334'} location='São Paulo' url={'#'} />
                        <VacancyCountCard title='Page em Destaque' vacancyQty={'12,334'} location='São Paulo' url={'#'} />
                        <VacancyCountCard title='Page em Destaque' vacancyQty={'12,334'} location='São Paulo' url={'#'} />
                        <VacancyCountCard title='Page em Destaque' vacancyQty={'12,334'} location='São Paulo' url={'#'} />
                    </div>
                </div>
            </div>
        </MobileLayout>
    );
}
