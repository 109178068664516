import styled from "styled-components";

export type LoadingContentProps = {
    width?: string;
    height?: string;
}


const LoadingContent = styled.div<LoadingContentProps>`
    width: ${(props) =>  props.width || '100%'};
    height: ${(props) =>  props.height || '22px'};
    border-radius: 4px;
    background: #EAECF0;
`

export default LoadingContent;