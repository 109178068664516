import { makeObservable, observable, action, runInAction } from 'mobx';
import searchVacancies from "@/services/search-vacancies";
import type {SearchVacanciesProps, SearchVacancyOutput} from "@/services/search-vacancies";
import useActivateStore from "@/hooks/stores/use-activate-store";


export class SearchVacanciesStore {
    @observable output: SearchVacancyOutput|null = null;
    @observable filters: SearchVacanciesProps = {};
    @observable isLoading: boolean = false;
    @observable hasError: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action
    setOutput(output: SearchVacancyOutput) {
        this.output = output;
    }

    @action
    setFilters(filters: SearchVacanciesProps) {
        this.filters = filters;
        this.searchVacancies();
    }

    @action
    setLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @action
    async searchVacancies(): Promise<void> {
        this.setLoading(true);
        try {
            const response: SearchVacancyOutput = await searchVacancies(this.filters);
            runInAction(() => {
                this.output = response;
                this.isLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
                console.error('Failed to fetch suggestions', error); //todo: criar tratativa de erros
            });
        }
    }
}

export const useSearchVacanciesStore = () => useActivateStore<SearchVacanciesStore>(new SearchVacanciesStore());