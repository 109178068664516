import NavbarMobile from "@/components/templates/NavBarMobile/navbar-mobile";
import MobileSearch from "@/components/templates/MobileSearch/mobile-search";
import MainCard from "@/components/molecules/MainCard/main-card";
import {useState} from "react";

export default function MobileLayout({children, onScrollFinish}: {
    children: React.ReactNode,
    onScrollFinish?: () => void
}) {
    const [lastScrollTop, setLastScrollTop] = useState(0);

    const handleScroll = (e: any) => {
        if (!onScrollFinish) return;
        if (!e.target) return;

        const {scrollTop, scrollHeight, clientHeight} = e.target;
        const bottom = scrollHeight - scrollTop;
        const isUserScrollingBottom = scrollTop > lastScrollTop;

        if (isUserScrollingBottom && bottom <= (clientHeight + 200)) {
            onScrollFinish();
        }

        setLastScrollTop(scrollTop);
    };

    return (
        <main
            className="bg-purple-700 grid gap-5"
            style={{maxHeight: '100vh', overflowY: 'scroll'}}
            onScroll={handleScroll}
        >
            <NavbarMobile/>
            <MobileSearch/>
            <MainCard>
                {children}
            </MainCard>
        </main>
    )
}