import httpClient from "@/utils/http-client";
import {strlen} from "stylis";

export type SearchVacanciesProps = { search?: string, location?: string };

export type SearchVacancyOutput = {
    total: number;
    results: SearchVacancyResume[];
}

export type SearchVacancyResume = {
    token: string;
    company_name: string;
    company_logo: string | null;
    title: string;
    location: string;
    work_type: VacancyWorkType;
    posted_at: string;
}

export type WorkTypeName = 'PRESENCIAL' | 'HOME_OFFICE' | 'HIBRIDO' | 'NAO_INFORMADO';
export type WorkTypeValue = 'Presencial' | 'Home office' | 'Hibrido' | 'Não Informado';

export type VacancyWorkType = {
    name: WorkTypeName;
    value: WorkTypeValue;
}


export default async function searchVacancies(query: SearchVacanciesProps): Promise<SearchVacancyOutput> {
    let queryString = new URLSearchParams(query).toString();
    queryString = strlen(queryString) > 0 ? '?' + queryString : '';
    const output = await httpClient.get<SearchVacancyOutput>('/api/vacancies' + queryString);
    return output.data;
}