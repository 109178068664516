import React, {CSSProperties, useEffect, useRef} from 'react';

const root: CSSProperties =  {
    position: 'relative',
    width: '100%',
}

const autocompleteDiv: CSSProperties = {
    position: 'absolute',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    border: '1px solid #EAECF0',
    backgroundColor: '#F5F7FA',
    overflowY: 'scroll',
    overflowX: 'hidden',
    width: '100%',
    boxShadow: '0 3px 2px 2px rgba(16, 24, 40, 0.05)',
    display: 'grid',
    fontFamily: 'Inter',
    zIndex: 2,
    boxSizing: 'border-box',
    maxHeight: '200px',
    transition: 'background-color 0.3s ease',
}

const styles = {
    root: {
        position: 'relative',
        width: '100%',
    },
    autocompleteContent: {
        display: 'grid',
        gap: '10px',
        width: '100%',
        padding: '5px',
    },
    autocompleteTitle: {
        color: '#344054',
        fontFamily: 'Inter',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 'bold',
    },
    autocompleteField: {
        color: '#42307D',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '25px',
    },
    autocompleteFieldHover: {
        backgroundColor: '#EAECF0',
        color: '#6941C6',
        cursor: 'pointer',
    },
};

export type AutocompleteItem = {
    name: string;
    filter: string;
    options: Option[];
};

export type Option = {
    name: string;
    slug: string;
};

export type AutocompleteSelectDropdownProps = {
    autocomplete: AutocompleteItem[];
    onSelectItem: (item: AutocompleteItem, option: Option) => void;
    onClickOutside: () => void;
};

const AutocompleteSelectDropdown = ({ autocomplete = [], onSelectItem, onClickOutside }: AutocompleteSelectDropdownProps) => {
    const ref = useRef<HTMLDivElement | null>(null);

    const dispatchItemSelected = (item: AutocompleteItem, option: Option) => {
        onSelectItem(item, option);
    };

    const autocompleteHasItems = () => {
        if (autocomplete.length === 0) return false;

        return !allAutocompleteOptionsAreEmpty();
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClickOutside]);

    const allAutocompleteOptionsAreEmpty = () => {
        const qtyOptions = autocomplete.length;
        const qtyEmpty = autocomplete.filter(option => option.options.length === 0).length;

        return qtyOptions === qtyEmpty;
    };

    return (
        <div style={root}>
            {autocompleteHasItems() && (
                <div
                    style={autocompleteDiv}
                    id="autocomplete_dropdown"
                    ref={ref}
                >
                    {autocomplete.map((autocompleteOption, index) => (
                        autocompleteOption.options.length > 0 && (
                            <div key={index} style={styles.autocompleteContent}>
                                <span style={styles.autocompleteTitle}>{autocompleteOption.name}</span>
                                <div>
                                    {autocompleteOption.options.map((autocompleteField, key) => (
                                        <div
                                            key={key}
                                            style={styles.autocompleteField}
                                            onClick={() => dispatchItemSelected(autocompleteOption, autocompleteField)}
                                            className='mobile_search-autocomplete_field hover:bg-gray-200 hover:cursor-pointer'
                                        >
                                            <span>{autocompleteField.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    ))}
                </div>
            )}
        </div>
    );
};

export default AutocompleteSelectDropdown;